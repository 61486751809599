.load-overlay{position:fixed;top:0;left:0;width:100%;height:100%;z-index:100000000;}
.load-overlay .overlayContent{position:relative;width:100%;height:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;transition:0.5s cubic-bezier(0.77,0,0.18,1);}
 
.loader{width:128px;height:128px;border:3px solid #006AFF;border-bottom:3px solid transparent;border-radius:50%;position:relative;-webkit-animation:spin 1s linear infinite;animation:spin 1s linear infinite;display:flex;justify-content:center;align-items:center;}
.loader .inner{width:64px;height:64px;border:3px solid transparent;border-top:3px solid #006AFF;border-radius:50%;-webkit-animation:spinInner 1s linear infinite;animation:spinInner 1s linear infinite;}
@-webkit-keyframes spin{
	0%{transform:rotate(0deg);}
	100%{transform:rotate(360deg);}
}
@keyframes spin{
	0%{transform:rotate(0deg);}
	100%{transform:rotate(360deg);}
}
@-webkit-keyframes spinInner{
	0%{transform:rotate(0deg);}
	100%{transform:rotate(-720deg);}
}
@keyframes spinInner{
	0%{transform:rotate(0deg);}
	100%{transform:rotate(-720deg);}
}