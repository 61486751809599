header{width:100%;padding-bottom:16px;padding-top:16px;position:fixed;top:0;width:100%;left:0;z-index:12345;background:#fff;
	-webkit-transition:all 0.4s;transition:all 0.4s;}
header.sticky-fixed{
	box-shadow:0px 0px 28px rgba(0,0,0,0.07);
}	
.header-main{width:100%;position:relative;display:flex;flex-wrap:wrap;align-items:center;padding:0 30px;justify-content:space-between;}
.logo{max-width:247px;width:100%;-webkit-transition:all 0.3s;transition:all 0.3s;}
.logo a{display:block;line-height:0;}
.logo img{width:100%;}
nav ul{list-style:none;margin:0;padding:0;display:flex;flex-wrap:wrap;}
nav ul li{margin-right:33px;line-height:1;position:relative;}
nav ul li:last-child{border:0;padding-right:0;margin-right:0;}
nav li a{font-family:Inter;font-style:normal;font-weight:500;font-size:14px;color:#5B6987;}
nav li a:hover,nav li.active a{color:#031B4E;}
nav li:hover:after{opacity:1;visibility:visible;}
nav ul li:after{content:"";position:absolute;left:0;bottom:-28px;width:100%;height:3px;background:#006AFF;opacity:0;visibility:hidden;transition:all 400ms ease-in-out;-o-transition:all 400ms ease-in-out;-webkit-transition:all 400ms ease-in-out;-moz-transition:all 400ms ease-in-out;}
nav ul li.active:after{opacity:1;visibility:visible;}
nav li a span{font-weight:900;font-size:10px;line-height:12px;text-align:center;text-transform:uppercase;padding:2px 4px;position:absolute;right:-18px;top:-18px;background:#006AFF;border-radius:2px;color:#FFFFFF;}
.menu-box nav ul{display:block;}
.menu-box .logo{margin-left:0;max-width:211px;margin-bottom:32px}
.menu-box  .logo + nav{display:block;}
.side-nav ul{list-style:none;margin:0;padding:0;display:flex;flex-wrap:wrap;}
.side-nav ul li{margin-left:8px}
.side-nav ul li a{padding:10px 29px;font-size:14px;border-radius: 20px}
.selectBox-dropdown-menu{display:none;}
.active-region .selectBox-dropdown-menu,.active-category .selectBox-dropdown-menu{display:block;}
footer{padding:28px 48px;background:#fff;}
.footer-main{max-width:1156px;margin:0 auto;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;}
.footer-menu ul{display:flex;flex-wrap:wrap;margin:0;padding:0;list-style:none;align-items:center;}
.footer-menu ul li{margin-left:33px;}
.footer-menu li a{color:#5B6987;font-weight:500;font-size:14px;}
.footer-menu li a:hover{color:#006AFF;}
.footer-main p{margin-bottom:0;padding-bottom:0;color:#5B6987;font-weight:500;font-size:14px;}
.banner-content h1{margin-bottom: 15px}
.play-btn img{vertical-align: middle;display: inline-block;}
@media only screen and (max-width:1199px){
	.header-main{padding:0 24px;}
	nav ul li{margin-right:8px;}
	nav li a{font-size:12px;}
	.side-nav ul li a{padding:10px 20px;}
	.content-block{max-width:366px;}
	.like-share-comment-wrap{margin-top:-74px;}
	.menu-box  .logo + nav  li{margin-bottom:36px}
	.menu-box  .logo + nav  li a{font-size:14px;position:relative;display: block;}
	.menu-box  .logo + nav ul li:after{bottom:-9px;}
	.menu-box .side-nav ul li{margin-left:0;margin-bottom:10px;}
	.menu-box .side-nav ul{display:block;}
	.menu-box .side-nav ul li a{padding:14px 32px;width:100%;font-size:16px;}

}
@media only screen and (max-width:991px){
	.logo + nav{display:none;}
	.side-nav{margin-left:auto;}
	.logo{margin-left:50px;max-width: 247px}
	.side-nav ul li a {
    padding: 10px 23px;
    font-size: 14px;
    display: block;
}.footer-menu ul li {
    margin-left: 11px;
}footer {
    padding: 28px 28px;
   
}nav li a span{ right:127px;}
}

@media only screen and (max-width:767px){
	.menu-box .side-nav ul li a{border-radius: 25px}
		footer{padding:56px 28px 60px;}
 
	.footer-main{display:block;text-align:center;}
	.footer-menu ul{display:block;}
	.footer-menu ul li{margin-left:0;margin-top:23px;}
	.get-started .slick-slide img{margin:0 auto;}
	.our-awards .container{padding-left: 20px;padding-right: 0}
	.our-awards-box  img {
    margin:0 auto;
}
 .logo{margin-left:41px;max-width: 120px}
 .side-nav ul li {
    margin-left: 0;
}
/*.our-awards-wrap .slick-slide {
    margin: 0 12px;
}*/
 
/*.our-awards-wrap .slick-track{
    margin: 0 -12px;
}
*/

}
@media only screen and (max-width:360px){

.side-nav ul li a {
    padding: 10px 10px;
}
}