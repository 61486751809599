.wt-recent-users-toaster-popup{position:fixed;z-index:2;bottom:50px;left:50px;min-height:50px;display:grid;grid-template-columns:56px 235px;border-radius:12px;padding:10px;box-shadow:0px 0px 19px rgba(0,0,0,0.13);background-color:#ffffff;grid-column-gap:5px;transition:all 0.4s ease-in-out;z-index:12340;align-items:center;}
.wt-recent-users-toaster-popup.fade-in{opacity:1;}
.wt-recent-users-toaster-popup.fade-out{opacity:0;}
.wt-recent-users-toaster-popup .content{font-size:14px;font-weight:500;color:#1d2026;}
.wt-recent-users-toaster-popup .name{font-weight:700;font-size:14px;color:#0aa1d8;}
.wt-recent-users-toaster-popup .avatar .bg-container{width:51px;height:51px;max-width:51px;max-height:51px;border-radius:50%;box-shadow:0 0 4px 0 rgb(0 0 0 / 50%);border:solid 2px #ffffff;margin-top:1px;overflow:hidden;}
.wt-recent-users-toaster-popup .avatar .bg-container img{width:100%;}
.wt-recent-users-toaster-popup .content .recent{line-height:1.2;letter-spacing:normal;color:#647287;margin-bottom:2px;padding-top:4px;font-size:12px;font-weight:400;padding-bottom:1px;}
.wt-recent-users-toaster-popup .content .amount{color:#0aa1d8;font-weight:700;}
.wt-recent-users-toaster-popup .content .location{font-size:14px;font-weight:500;color:#1d2026;}
.wt-recent-users-toaster-popup .reward.ng-star-inserted{color:#1d2026;font-size:12px;font-weight:600;}
@media only screen and (max-width:767px){
	.wt-recent-users-toaster-popup{left:50%;bottom:20px;margin:0 auto;justify-content:center;transform:translateX(-50%);}
}